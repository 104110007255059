<template>
  <div>
    <img class="map" v-if="isValid" :src="sourceMap">
    <p v-else class="text-center">
     {{ $t("campaigns.data-bank.location.error-map") }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    lat: {
      required: true,
    },
    lng: {
      required: true,
    },
  },
  data() {
    return {
      scale: 3,
      zoom: 16,
    };
  },
  computed: {
    apiKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    },
    staticUrl() {
      return process.env.VUE_APP_GOOGLE_MAPS_STATIC_URL;
    },
    sourceMap() {
      const center = `${this.lat},${this.lng}`;
      const marker = `color:red|${this.lat},${this.lng}`;
      const size = '280x220';

      return `${this.staticUrl}?key=${this.apiKey}&center=${center}&zoom=${this.zoom}&size=${size}&scale=${this.scale}&markers=${marker}`;
    },
    isValid() {
      return this.lat && this.lng;
    },
  },
};

</script>


<style scoped lang="scss">
  .map {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
